import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index.js'

const Home = () => import('../views/home/Home')
const HomePage = () => import('../views/homepage/HomePage')
const Introduction = () => import('../views/introduction/Introduction')
const News = () => import('../views/news/News')
const NewsDetail = () => import('../views/news/childComps/NewsDetail')
const Product = () => import('../views/product/Product')
const ProductList = () => import('../views/product/ProductList')
const Activity = () => import('../views/activity/Activity')
const ActivityDetail = () => import('../views/activity/childComps/ActivityDetail')
const Join = () => import('../views/join/Join')

Vue.use(VueRouter)

const routes = [
  {
    path: '',
    redirect: '/home',
  },{
    path: '/home',
    name: 'Home',
    component: Home,
    redirect: '/homepage',
    children: [
      {
        path: '/homepage',
        name: 'homepage',
        component: HomePage
      },{
        path: '/introduction',
        name: 'introduction',
        component: Introduction
      },{
        path: '/news',
        name: 'news',
        component: News
      },{
        path: '/news/newsdetail',
        name: 'newsdetail',
        component: NewsDetail
      },{
        path: '/product',
        name: 'product',
        component: Product
      },{
        path: '/productlist',
        name: 'productlist',
        component: ProductList
      },{
        path: '/activity',
        name: 'activity',
        component: Activity
      },{
        path: '/activity/activitydetail',
        name: 'activitydetail',
        component: ActivityDetail
      },{
        path: '/join',
        name: 'join',
        component: Join
      }
    ]
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

//挂载路由导航守卫
router.beforeEach((to,form,next) => {
  const title_list = [
    {
      path: "/homepage",
      name: "首页",
    },
    {
      path: "/introduction",
      name: "集团简介",
    },
    {
      path: "/news",
      name: "新闻动态",
    },
    {
      path: "/productlist",
      name: "旗下产品",
    },
    {
      path: "/activity",
      name: "品牌活动",
    },
    {
      path: "/join",
      name: "加入我们",
    },
  ]
  title_list.map((item,index) => {
    if(to.path == item.path) {
      // 将头部导航的索引值保存在缓存中
      window.sessionStorage.setItem("currentIndex", index);
      // 为vuex里的导航索引值重新赋值
      store.commit("changeCurrentIndex", index);
    }
  })
  next()
})
router.afterEach(() => {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
})
export default router
