import {request} from "./request";

function getMainData(data) {
  return request({
    url: '/getMainData',
    method: 'GET',
    data
  })
}
function getGroupData(data) {
  return request({
    url: '/getGroupData',
    method: 'GET',
    data
  })
}
function getNewsList(data) {
  return request({
    url: '/getNewsList',
    method: 'GET',
    params: data
  })
}
function getNewsData(data) {
  return request({
    url: '/getNewsData',
    method: 'GET',
    params: data
  })
}
function getProductList(data) {
  return request({
    url: '/getProductList',
    method: 'GET',
    params: data
  })
}
function getProductData(data) {
  return request({
    url: '/getProductData',
    method: 'GET',
    params: data
  })
}
function getBrandActList(data) {
  return request({
    url: '/getBrandActList',
    method: 'GET',
    params: data
  })
}
function getJoinUsList(data) {
  return request({
    url: '/getJoinUsList',
    method: 'GET',
    params: data
  })
}
function getLineList(data) {
  return request({
    url: '/getLineList',
    method: 'GET',
    params: data
  })
}
function getBannerList(data) {
  return request({
    url: '/getBanner',
    method: 'GET',
    params: data
  })
}
function getBrandActInfo(data) {
  return request({
    url: '/getBrandActInfo',
    method: 'GET',
    params: data
  })
}
function getRecruitList(data) {
  return request({
    url: '/getRecruitList',
    method: 'GET',
    params: data
  })
}
export default {
	getMainData,
  getGroupData,
  getNewsList,
  getNewsData,
  getProductList,
  getProductData,
  getBrandActList,
  getJoinUsList,
  getLineList,
  getBannerList,
  getBrandActInfo,
  getRecruitList,
}
