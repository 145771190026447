import axios from 'axios'
//引入进度条插件
import nprogress from 'nprogress'
//引入进度条样式
import "nprogress/nprogress.css"
//引入当前环境的接口域名
import config from "@/config";
var baseURL = config.ApiUrl;
export function request(config) {
  // 1.创建axios的实例
  const instance = axios.create({
    baseURL: baseURL,
    timeout: 5000
  })

  // 2.axios的拦截器
  // 2.1.请求拦截的作用
  instance.interceptors.request.use(config => {
    //进度条开启
    nprogress.start()
    //设置token请求头，确保在发送请求的时候有权限
    // config.headers.Authorization = window.sessionStorage.getItem('token')
    return config
  }, err => {
    console.log(err);
  })

  // 2.2.响应拦截
  instance.interceptors.response.use(res => {
    //进度条结束
    nprogress.done()
    return res.data
  }, err => {
    console.log(err);
  })

  // 3.发送真正的网络请求
  return instance(config)
}
