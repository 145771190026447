const env = {
    // 开发环境
    dev: {
        ApiUrl: 'http://localhost.yuetustar.com/home/api',
        htmlUrl: 'http://admin.178read.com',
        baseUrl: 'http://localhost:8081/',
    },
    // 测试环境
    test: {
        ApiUrl: 'http://admin.178read.com/home/api',
        htmlUrl: 'http://admin.178read.com',
        baseUrl: 'http://m.178read.com/',
    },
    // 生产环境
    pro: {
        ApiUrl: 'https://admin.yuetustar.com/home/api',
        htmlUrl: 'https://admin.yuetustar.com',
        baseUrl: 'https://m.yuetustar.com/',
    },
}
module.exports = {
    ...env.pro
}