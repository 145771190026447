import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

//图片懒加载
import VueLazyload from "vue-lazyload";
Vue.use(VueLazyload)

//引入网络请求的方法
import api from '@/api/index'

//导入字体图标文件
import '@/assets/iconfont/iconfont.css'

//引入element-ui
import '@/element/index.js'

//引入echarts
import * as echarts from 'echarts'

//全局引入鼠标悬浮样式
import 'hover.css'

//全局引入滚动插件
import scroll from 'vue-seamless-scroll'
Vue.use(scroll)

// import Wow from 'wowjs'
// import 'wowjs/css/libs/animate.css'
// Vue.prototype.$wow=Wow

//引入echarts
Vue.prototype.$echarts = echarts;
Vue.prototype.$api = api

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
