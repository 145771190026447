import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
      // 头部一级导航栏的下标值
      currentIndex: window.sessionStorage.getItem('currentIndex')||0,
      // 旗下产品详情数据
      product_data: JSON.parse(window.sessionStorage.getItem('currentProductData'))||{},
      // 旗下产品详情的盒子到顶部的距离
      scroll_top: 0,
    },
    mutations: {
      // 修改头部下标值
      changeCurrentIndex(state,index) {
        state.currentIndex = index
      },
      // 获取新的旗下产品详情数据
      getProductData(state,data) {
        state.product_data = data
        state.product_data.showStatus = false
      },
      // 修改旗下产品详情的盒子到顶部的距离
      changeScrollTop(state,top) {
        state.scroll_top = top
      },
    },
    actions: {},
    modules: {}
})