import Vue from 'vue'
// 按需引入：base.css 基础样式必须引入，其它组件样式按需引入
import "element-ui/lib/theme-chalk/index.css";

import {
    Message,
    Carousel,
    CarouselItem,
    Backtop,
    Input,
    Button,
    Pagination,
    Popover,
    Collapse,
    CollapseItem,
} from 'element-ui';//从element-ui中引入按钮

Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Backtop);
Vue.use(Input);
Vue.use(Button);
Vue.use(Pagination);
Vue.use(Popover);
Vue.use(Collapse);
Vue.use(CollapseItem);

Vue.prototype.$message = Message